.panel {
  border-radius: 0;
}
.panel.panel-default {
  background: $silver;
  border: none;
  .panel-heading {
    background: $platinum;
    color: $slate;
  }
  .panel-body {
    border: 1px solid $platinum;
    background: $silver;
  }
  .panel-heading + .panel-body {
    border-top: none;
  }
  .faux-panel-footer {
    padding: 15px;
  }
}
