.card {
  margin: 20px;
  border: 1px solid $platinum;
  background-color: $white;
}

.card-panel {
  @extend .card;
  padding: 15px;
  .fill {
    margin: -15px; // kind of a hack for now, need to define components better
  }
}

.card-panel-header {
  padding: 15px;
  border-bottom: 1px solid $platinum;
  background-color: $silver;
  color: $slate;
  &.secondary {
    background-color: $white;
    color: inherit;
  }
}
