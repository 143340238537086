// tooltips
.tooltip-danger {
  .tooltip-inner {
    background-color: map-get($danger, base);
    border: 1px solid map-get($danger, base);
    color: $white;
  }
  .tooltip-arrow {
    //TODO: clean this up
    border-top-color: map-get($danger, base) !important;
  }
}

.tooltip.top .tooltip-arrow {
  margin-left: -6px;
  border-width: 6px 6px 0;
}

#notifications {
  width: 350px;
  .popover-content {
    padding: 14px;
  }
}

// special tooltip for the status page that needs to be bigger and left justified
.details-status-tooltip .tooltip-inner {
  text-align: left;
  max-width: inherit; // get rid of the silly max width for tooltips for this case.
}

.preview .tooltip-inner {
  max-width: 350px;
  text-align: left;
}

.shipment-details .popover {
  max-width: inherit;
  table td {
    padding: 10px;
    vertical-align: top;
    text-align: left;
  }
}
