// All these "!important" markers are super hacky but unfortunately inline styles are hard to override
.borderless > .Select-control {
  border: none;
  background-color: transparent;
}

.btn-group .dropdown-menu {
  min-width: 220px;
  a {
    line-height: 16px;
    &:hover {
      background: $horizon;
    }
  }
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li.dropdown-item:focus,
.dropdown-menu > li.dropdown-item:hover {
  background: $horizon;
}

ul.dropdown-menu {
  padding: 0;
}

.dropdown-menu > li > a {
  padding: 10px 23px;
}

.dropdown-menu > li.dropdown-item {
  padding: 10px 0;
}

// the div>div is to handle tooltips in dropdown items
.dropdown-menu > li > div > a,
.dropdown-menu > li > div > div > a {
  padding: 0px 23px;
  display: inline-block;
  width: 100%;
}
