// Not actually an image, but pretends to be one
div.preview-placeholder {
  height: 100%;
  width: 100%;
  background-color: $platinum;
  text-align: center;
  display: flex;
  align-items: center;
  color: $slate;
  h3 {
    margin: 0;
  }
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $link;
  background: $white;
  &:hover {
    border: 1px solid $link-hover;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

// image preview modal wrapper
.preview div {
    width: 100%;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 200px 0;
}

.spinner.no-padding{
  padding: 0;
}

// spinner
.spinner.overlay {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 120px);
  background: rgba(lighten($platinum, 5), .5);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
