/*
  bootstrap's modal-lg is comically large, and modal-sm is uselessly small.
  let's make something actually useful.
*/

@media (min-width: 768px) {
  .modal-md {
    width: 400px;
  }
}

@media (min-width: 992px) {
  .modal-md {
    width: 600px;
  }
}

/* sortable lists inside modals */
.sortableHelper {
   z-index: 10000 !important;
}
