// derived from http://bootsnipp.com/snippets/featured/material-design-tab-style
// TODO move to styleguide, temporary while this style is being defined

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover { border-width: 0; }
.nav-pills > li > a {
  border: none;
  color: map-get($cobalt, base);
  text-transform: uppercase;
  font-weight: 400;
  background: transparent;
  background-color: transparent;
}
.nav-pills > li.active > a {
  color: $link !important;
  background-color: transparent;
}
.nav-pills > li > a:hover, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  border: none;
  color: $link-hover !important;
  background: transparent;
}
.nav-pills > li > a::after {
  content: "";
  background: $link;
  height: 4px;
  position:
  absolute;
  width: 100%;
  left: 0px;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}
.nav-pills > li.active > a::after, .nav-pills > li:hover > a::after {
  transform: scale(1);
}

.nav-pills > li.disabled > a::after {
  display: none;
}

.pill-nav > li > a::after {
  background: $link-hover none repeat scroll 0% 0%;
  color: $white;
}
