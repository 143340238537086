// for rotating an icon 180 degrees (ie ^ to v)
.toggle-rotate {
  transform: rotate(0deg);
  transition: transform .25s linear;
}

.toggle-rotate.open {
  transform: rotate(-180deg);
  transition: transform .25s linear;
}

// for rotating an item 90 degrees (ie > to v)
.toggle-quarter {
  transform: rotate(0deg);
  transition: transform 0.4s ease-in-out;
}

.toggle-quarter.open {
  transform: rotate(90deg) translateX(0px);
  transition: transform 0.4s ease-in-out;
}
