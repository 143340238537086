// brand
$persimmon: (
  base: #f0553a,
  darker: #D63625,
  darkest: #BC2111,
);

$cobalt: (
  base: #3a414c,
  darker: #212730,
  darkest: #0D1116,
);



// Add percentage of white to a color
@function tint($color, $percent){
  @return mix(white, $color, $percent);
};

// Add percentage of black to a color
@function shade($color, $percent){
  @return mix(black, $color, $percent);
};

// interaction
$teal: (
  base: #0088A9,
  darker: #00738E,
  darkest: #005C75,
);


$dark-teal: #006F8A;
$dark-teal-darker: #005B70;
$dark-teal-darkest: #005B70;

$sky: #A9D6E5;
$sky-darker: #87B9C9;
$sky-darkest: #689BAF;

$granite: #4F5D75;
$granite-darker: #354259;
$granite-darkest: #212C3F;

// tonal
$dark-cobalt: #333943;
$slate: #697582;
$light-slate: #8090a2;
$alloy: #c4cdd6;
$platinum: #e1e6eb;
$silver:#f7f9fa;
$horizon: #edf3f9;
$white: #ffffff;

// bootstrap alert types
$success: (
  base: #35AD96,
  darker: #22917E,
  darkest: #127769,
);

$info: (
  base: #41B5DC,
  darker: #2B9DC1,
  darkest: #188AA8,
);

$warning: (
  base: #FFA23F,
  darker: #E58628,
  darkest: #CC7712,
);

$danger: (
  base: #D24A35,
  darker: #B73721,
  darkest: #9E2810,
);

// link colors
$link: #41B5DC;
$link-visited: #005C75;
$link-hover: #0088A9;

//fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

$font-stack-body: "Open Sans", "San Francisco", "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-stack-brand: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Gotham Rounded", "Gotham", "San Francisco", "Helvetica Neue", "Segoe UI", "Lucida Grande", Helvetica, Arial, sans-serif;
$font-stack-mono: "Menlo", "Monaco", monospace;
