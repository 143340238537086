.rdt {
  &.narrow {
    width: 100%;
  }
  &.right-open {
    .rdtPicker {
      right: 0;
    }
  }
}

.datetime {
  width: 100%;
  max-width: 300
}
