@import "./variables.scss";
@import "./alerts.scss";
@import "./card.scss";
@import "./datepicker.scss";
@import "./dropdown.scss";
@import "./forms.scss";
@import "./icons.scss";
@import "./images.scss";
@import "./labels.scss";
@import "./panel.scss";
@import "./tooltips.scss";
@import "./table.scss";
@import "./nav-pills.scss";
@import "./modal.scss";
@import "./report.scss";
@import "./treeselect.scss";
@import "./textfield.scss";
@import "./feedback.scss";

// utility
.clickable {
  cursor: pointer;
}

hr {
  border-color: $platinum;
}

.h6, h6 {
  color: $slate;
  font-weight: 300;
}

i.fa.fa-sm {
  font-size: 13px;
}
