@media (min-width: 992px) {
  .right-divider {
    border-right: 2px solid #C3CDD6;
  }
}

@media (max-width: 991px) {
  .bottom-divider {
    border-bottom: 2px solid #C3CDD6;
    padding: 30px 0;
  }
}