// labels
.label {
  &.small {
    padding: 4px 6px;
  }

  &.label-danger {
    &.ghost {
      border: 2px solid map-get($danger, base);
      color: map-get($danger, base);
      background: white;
    }
  }
  &.label-warning {
    &.ghost {
      border: 2px solid map-get($warning, base);
      color: map-get($warning, base);
      background: white;
    }
  }
  &.label-success {
    &.ghost {
      border: 2px solid map-get($success, base);
      color: map-get($success, base);
      background: white;
    }
  }
  &.label-info {
    &.ghost {
      border: 2px solid map-get($info, base);
      color: map-get($info, base);
      background: white;
    }
  }
  &.label-default {
    &.ghost {
      border: 2px solid $slate;
      color: $slate;
      background: white;
    }
    &.beta {
      display: inline-block;
      vertical-align: top;
      margin: 3px 0 0 3px;
      font-size: 0.7em;
      font-weight: normal;
    }
  }
}
