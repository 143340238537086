table.table-hover {
  margin: 0;
  tbody {
    tr {
      &.selected {
        background: fade-out($sky, .7);
      }
    }
  }
}

div.faux-table-header {
  background-color: $platinum;
  color: $slate;
  font-size: 10pt;
  text-transform: uppercase;
  vertical-align: top;
  border-bottom: 1px solid $platinum;
  font-weight: 600;
  padding: 18px 8px;
}

div.faux-table-row {
  padding: 12px 0;
  background-color: $white;
  border-top: 1px solid $platinum;
  div.clearfix { //slightly hacky fix to get text centered in only the short description
    display: flex;
    align-items: center;
    .checkbox {
      margin: 0;
    }
  }
}

// New order details page
div.faux-table-header-new {
  background-color: $platinum;
  color: $slate;
  font-size: 11px;
  text-transform: uppercase;
  vertical-align: top;
  border-bottom: 1px solid $platinum;
  font-weight: 600;
  padding: 16px 11px;
}

div.faux-table-row-new {
  padding: 11px;
  background-color: $white;
  border-top: 1px solid $platinum;
  div.clearfix { //slightly hacky fix to get text centered in only the short description
    display: flex;
    align-items: center;
    .checkbox {
      margin: 0;
    }
  }
}

table.table-condensed {
  border: none;
  td {
    padding: 5px 0;
  }
  tr {
    border: none;
    td {
      border: none;
    }
  }
}

table {
  // TODO: remove this when it gets fixed in mcp-ux-css
  // it seems to be a lingering bootstrap style
  font-size: 14px;
}
